import { fromJS, isImmutable } from 'immutable';
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import ReactDataGrid, { SelectColumn, textEditor } from 'react-data-grid';

import { Button, Dimmer, Dropdown, Loader, Sidebar } from 'semantic-ui-react';

import styles from './styles.module.scss';

import StatusBar from '../StatusBar';
import Column from './Column';

export default forwardRef(function DataGrid(props, ref) {
    const {
        name = '',
        columns = [],
        rows = [],
        rowSelect = false,
        rowNumber = true,
        sortable = true,
        resizable = true,
        rowId = 'id',
        status = [],
        onSelect = () => {},
        onRowSelect = () => {},
        onAction = () => {},
        onChange = () => {},
        onColumnChange = () => {},
        refresh = true,
        addition = true,
        move = false,
        clone = false,
        remove = false,
        remit = false,
        setting = false,
        loading = false,
        selected = [],
        customInput,
        rightInput,
        defaultSortColumns = [],
    } = props;

    const initState = fromJS({
        sortColumns: [],
        selectedRows: new Set(),
    });

    const [state, setState] = React.useState(initState);

    const sortColumns = state.get('sortColumns').toJS();
    const selectedRows = state.get('selectedRows').toJS();

    useEffect(() => {
        if (!sortColumns.length) {
            setState(state.set('sortColumns', fromJS(defaultSortColumns)));
        }
    }, [sortColumns]);

    useEffect(() => {
        setState(state.set('selectedRows', fromJS(selected)));
    }, [selected]);

    useImperativeHandle(ref, () => ({
        handleSelectedRowsChange,
    }));

    const sorter = (a, b) => {
        const { formatter = () => {} } = columns.find(({ key }) => key === sortColumns[0]?.columnKey) ?? {};
        const formatterName = formatter.name;

        return sortColumns
            .map(({ columnKey, direction }) => {
                switch (true) {
                    case formatterName === 'NumericFormatter':
                        return direction === 'ASC'
                            ? (a[columnKey] ?? 0) - (b[columnKey] ?? 0)
                            : (b[columnKey] ?? 0) - (a[columnKey] ?? 0);
                    default:
                        return direction === 'ASC'
                            ? (a[columnKey] ?? '').toString().localeCompare((b[columnKey] ?? '').toString())
                            : (b[columnKey] ?? '').toString().localeCompare((a[columnKey] ?? '').toString());
                }
            })
            .reduce((a, b) => a || b, 0);
    };

    const handleRowChange = rows => {
        onChange(null, { name, value: fromJS(rows) });
    };

    const handleSelectedRowsChange = (selectedRows = new Set()) => {
        // setState(state.set('selectedRows', fromJS(selectedRows)));
        onRowSelect([...selectedRows]);
    };

    const handleSortColumnsChange = sortColumns => {
        setState(state.set('sortColumns', fromJS(sortColumns)));
    };

    const handleCellClick = (values, evt) => {
        const { column, row } = values;
        if (column.key !== 'select-row') {
            // setState(state.set('selectedRows', fromJS([row[rowId]])));
            onSelect && onSelect(row, column);
        }
    };

    const handleSettingClick = () => {
        ColumnRef?.current.handleOpen(columns);
    };

    const handleColumnChange = (values, column) => {
        onColumnChange(values);
    };

    const _rows = (isImmutable(rows) ? rows.toJS() : rows instanceof Array ? rows : [])
        .sort(sorter)
        .map((row, idx) => ({
            ...row,
            idx: idx + 1,
        }));

    const _columns = (rowSelect ? [SelectColumn] : [])
        .concat(
            rowNumber
                ? [
                      {
                          key: 'idx',
                          name: '项',
                          minWidth: 45,
                          width: 45,
                          maxWidth: 45,
                          frozen: true,
                          resizable: false,
                          style: { textAlign: 'center' },
                      },
                  ]
                : []
        )
        .concat(
            (isImmutable(columns) ? columns.toJS() : columns instanceof Array ? columns : [])
                .filter(({ disabled }) => !disabled)
                .map(column => {
                    const editor = column.editor ? column.editor : column.editable ? textEditor : undefined;

                    return {
                        ...column,
                        resizable,
                        sortable,
                        draggable: true,
                        editor,
                    };
                })
        );

    const _status = [{ label: '项', value: _rows.length }, { spacer: true }].concat(status);

    const ColumnRef = useRef();

    return (
        <div className={styles.container}>
            {(customInput || refresh || addition || remove || clone || remit || move || setting) && (
                <div className="toolbar">
                    <div className="toolbar_left">
                        {refresh && (
                            <Button
                                icon="refresh"
                                color="teal"
                                type="button"
                                size="tiny"
                                content="刷新"
                                action="refresh"
                                onClick={onAction}
                            />
                        )}
                        {addition && (
                            <Button
                                icon="add"
                                color="blue"
                                type="button"
                                size="tiny"
                                content="新增"
                                action="add"
                                onClick={onAction}
                            />
                        )}
                        {remove && (
                            <Dropdown
                                icon={null}
                                value=""
                                selectOnBlur={false}
                                selectOnNavigation={false}
                                trigger={<Button color="red" icon="trash" type="button" size="tiny" content="删除" />}
                                action="destroy"
                                disabled={!selectedRows.length}
                                options={[{ value: 'delete', text: '你确定要删除吗？' }]}
                                onChange={onAction}
                            />
                        )}
                        {clone && (
                            <Button
                                icon="clone"
                                color="purple"
                                type="button"
                                size="tiny"
                                content="复制"
                                action="clone"
                                onClick={onAction}
                            />
                        )}
                        {remit && (
                            <Button
                                icon="file excel"
                                color="brown"
                                type="button"
                                size="tiny"
                                content="汇出"
                                action="export"
                                onClick={onAction}
                            />
                        )}
                        {move && (
                            <React.Fragment>
                                <Button
                                    color="grey"
                                    type="button"
                                    size="tiny"
                                    content="上移"
                                    action="up"
                                    onClick={onAction}
                                />
                                <Button
                                    color="grey"
                                    type="button"
                                    size="tiny"
                                    content="下移"
                                    action="down"
                                    onClick={onAction}
                                />
                            </React.Fragment>
                        )}
                        {customInput}
                    </div>
                    <div className="toolbar_right">
                        {setting && <Button basic size="tiny" content="设定" onClick={handleSettingClick} />}
                    </div>
                    {rightInput && <div className="toolbar_right">{rightInput}</div>}
                </div>
            )}
            <Sidebar.Pushable className="content">
                <Dimmer active={loading} inverted>
                    <Loader size="big" />
                </Dimmer>
                <ReactDataGrid
                    key={_rows.length}
                    className="rdg-light fill-grid"
                    columns={_columns}
                    rows={_rows}
                    rowKeyGetter={row => row[rowId]}
                    sortColumns={sortColumns}
                    selectedRows={new Set(selectedRows)}
                    onCellClick={handleCellClick}
                    onRowsChange={handleRowChange}
                    onSelectedRowsChange={handleSelectedRowsChange}
                    onSortColumnsChange={handleSortColumnsChange}
                />
                <Column ref={ColumnRef} onChange={handleColumnChange} />
            </Sidebar.Pushable>
            {!!status && <StatusBar status={_status} />}
        </div>
    );
});

export { default as CheckboxEditor } from './CheckboxEditor';
export { default as CheckboxFormatter } from './CheckboxFormatter';
export { default as DateEditor } from './DateEditor';
export { default as DateFormatter } from './DateFormatter';
export { default as DropdownEditor } from './DropdownEditor';
export { default as DropdownFormatter } from './DropdownFormatter';
export { default as ImageFormatter } from './ImageFormatter';
export { default as NumericFormatter } from './NumericFormatter';
export { default as PreviewFormatter } from './PreviewFormatter';
