import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { fromJS, is } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';

import { Sidebar } from 'semantic-ui-react';

import { DataGrid } from '../../Common';

import Actions from './actions';
import Search from './Search';
import SettingCustomerAction from '../Customer/actions';
import CoachActions from '../../Coach/actions';

const Detail = lazy(() => import('./Detail'));

export default function SettingCustomer(props) {
    const dispatch = useDispatch();

    const [selected, setSelected] = useState([]);

    const loading_index = useSelector(
        state => state.SettingScholarReducer.get('loading_index'),
        (a, b) => is(a, b)
    );

    const loading_show = useSelector(
        state => state.SettingScholarReducer.get('loading_show'),
        (a, b) => is(a, b)
    );

    const schools = useSelector(
        state => state.SettingScholarReducer.get('schools'),
        (a, b) => is(a, b)
    );

    const scholars = useSelector(
        state => state.SettingScholarReducer.get('scholars'),
        (a, b) => is(a, b)
    );

    const scholar = useSelector(
        state => state.SettingScholarReducer.get('scholar'),
        (a, b) => is(a, b)
    );

    const search = useSelector(
        store => store.SettingScholarReducer.get('search'),
        (a, b) => is(a, b)
    );

    const defaultColumns = useSelector(
        store => store.SettingScholarReducer.get('columns'),
        (a, b) => is(a, b)
    );

    const columns = useSelector(
        store => store.MainReducer.getIn(['gui', 'Scholar'], defaultColumns),
        (a, b) => is(a, b)
    ).toJS();

    useEffect(() => {
        dispatch(CoachActions.options());
        dispatch(SettingCustomerAction.options());
        dispatch(Actions.school());
    }, []);

    useEffect(() => {
        dispatch(Actions.requests());
    }, [search]);

    const handleSearch = (evt, values) => {
        const { name, value } = values;
        if (name) {
            dispatch(Actions.search(search.set(name, value instanceof Array ? value.join(',') : value)));
        } else {
            dispatch(Actions.search(search.merge(fromJS(values))));
        }
    };

    const handleAction = (evt, { action, value }) => {
        switch (action) {
            case 'refresh':
                return dispatch(Actions.requests());
            case 'update':
                return dispatch(Actions.create());
            case 'destroy':
                return dispatch(Actions.destroy({ selected }));
            case 'exclude':
                return dispatch(Actions.exclude({ selected }));
            case 'project':
                return dispatch(Actions.project({ selected, project: value }));
            case 'print':
                break;
            default:
        }
    };

    const handleChange = (evt, { name, value }) => {
        dispatch(Actions.show(scholar.set(name, value)));
    };

    const handleRowSelect = row => {
        setSelected(row);
    };

    const handleSelect = row => {
        return DetailRef?.current?.handleOpen(row.id);
    };

    const DetailRef = useRef();

    const schools_options = schools.filter(s => s.get('customers_id') === scholar.get('customers_id')).toJS();

    return (
        <div className="flexHorizontalContent">
            <Sidebar.Pushable className="flexHorizontalContent">
                <Search selected={selected} search={search} onSearch={handleSearch} onAction={handleAction} />
                <DataGrid
                    key={ JSON.stringify(search.toJS()) }
                    rowSelect
                    selected={selected}
                    refresh={false}
                    addition={false}
                    loading={loading_index}
                    columns={columns}
                    rows={scholars}
                    defaultSortColumns={[{ columnKey: 'name', direction: 'ASC' }]}
                    onAction={handleAction}
                    onSelect={handleSelect}
                    onRowSelect={handleRowSelect}
                />
                <Suspense fallback={null}>
                    <Detail
                        admin
                        schools_options={schools_options}
                        ref={DetailRef}
                        loading={loading_show}
                        scholar={scholar}
                        onAction={handleAction}
                        onChange={handleChange}
                    />
                </Suspense>
            </Sidebar.Pushable>
        </div>
    );
}

export { default as SettingScholarReducer } from './reducers';
export { default as SettingScholarAction } from './actions';
